import { Button } from "antd";
import { ReactNode, useContext } from "react";
import RowContext from "./RowContext";
import { HolderOutlined } from "@ant-design/icons";

interface Iprops {
  isDisabled?: boolean;
}

const DragHandle = ({ isDisabled }: Iprops) => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <div>
      {isDisabled ? (
        <Button
          type="text"
          size="small"
          icon={<HolderOutlined />}
          style={{ cursor: "not-allowed" }}
        />
      ) : (
        <Button
          type="text"
          size="small"
          icon={<HolderOutlined />}
          style={{ cursor: "move " }}
          ref={setActivatorNodeRef}
          {...listeners}
        />
      )}
    </div>
  );
};

export default DragHandle;
