import type { DragEndEvent, DragMoveEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Table } from "antd";
import Row from "./compoment/Row";
import DragHandle from "./compoment/DragHandle";
import { useMemo } from "react";

interface IPropsTableDragDrop {
  columns: any;
  dataSource: any;
  isDisabled?: boolean;
  onDragEnd?: (e: DragEndEvent) => void;
  onDragMove?: (e: DragMoveEvent) => void;
}

const TableDragDrop = (props: IPropsTableDragDrop) => {
  const { columns, dataSource, onDragEnd, onDragMove, isDisabled } = props;
  const dataSourceKeyString = useMemo(() => {
    return (
      dataSource?.map((item: any) => {
        return { ...item, key: item?.key?.toString() };
      }) || []
    );
  }, [dataSource]);

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
      onDragMove={onDragMove}
    >
      <SortableContext
        items={dataSourceKeyString.map((i: any) => i.key)}
        strategy={verticalListSortingStrategy}
        disabled={isDisabled}
      >
        <Table
          rowKey="key"
          components={{ body: { row: Row } }}
          scroll={{ x: "max-content" }}
          columns={[
            {
              key: "sort",
              align: "center",
              width: 50,
              render: () => (
                <div>
                  <DragHandle isDisabled={isDisabled} />
                </div>
              ),
            },
            ...columns,
          ]}
          dataSource={dataSourceKeyString}
          pagination={false}
        />
      </SortableContext>
    </DndContext>
  );
};

export default TableDragDrop;
