import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useState, useEffect } from "react";
import { Checkbox, Form, Input, Select, Spin, Table, notification } from "antd";
import { Box, Button, Text } from "@chakra-ui/react";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { toast } from "react-toastify";
import Styles from "./styled";
import { HAFT_HOUR, HOURS_PER_DAY, MONTH_FORMAT } from "constant/date";
import Column from "antd/es/table/Column";
import dayjs, { Dayjs } from "dayjs";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import SelectTime from "component/SelectTime";
import { Link, useParams } from "react-router-dom";
import SelectWithData from "component/SelectWithData";
import { IService, IServiceType, ITreatmentType } from "constant/interfaces";
import MESSAGES from "constant/messages";
import { ClockCircleFilled } from "@ant-design/icons";
import ROUTES from "constant/routes";
import { toBEData, toFEData } from "./function";
import { useWatch } from "antd/es/form/Form";
import CareSection, { ILongTermGoal } from "./components/CareSection";
import { DAY_IN_WEEK } from "enums/date";
import {
  defaultLongTermGoals,
  defaultShortTermGoals,
  frequencyOptions,
  shiftOptions,
} from "./constants";
import useResponsive from "hook/useResponsive";
import TableDragDrop from "component/table/TableDragDrop";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { toNumber } from "lodash";

interface ICarePlanProps {
  isNormal: boolean;
  isStaff: boolean;
}

export const hours = Array.from({ length: HOURS_PER_DAY + 1 }, (_, index) => {
  return {
    label: index < 10 ? `0${index}` : `${index}`,
    value: index,
  };
});

export const minutes = [
  { label: "00", value: 0 },
  { label: "30", value: HAFT_HOUR },
];

const CarePlan = ({ isNormal, isStaff }: ICarePlanProps) => {
  const [form] = Form.useForm();
  const changeableId = useWatch("id", form);
  const schedules = useWatch("schedules", form);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  // isSubmit is enabled mode
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const service = useService();
  const { id } = useParams();
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const [isShowScheduleError, setIsShowScheduleError] = useState(false);

  const initialFormValues = {
    schedules: [{}],
    long_term_goal_1: "",
    short_term_goal_1: "",
  };

  const [longTermGoals, setLongTermGoals] =
    useState<ILongTermGoal[]>(defaultLongTermGoals);
  const [shortTermGoals, setShortTermGoals] = useState<ILongTermGoal[]>(
    defaultShortTermGoals
  );

  const { isMobile, isTablet } = useResponsive();

  const fetchInitialData = async (lastMonth = false) => {
    let year_month = month;
    if (lastMonth) year_month = year_month.add(-1, "month");
    try {
      const currentFormId = form.getFieldValue("id");
      form.resetFields();
      if (lastMonth) form.setFieldValue("id", currentFormId);
      form.setFieldValue("schedules", [{}]);
      setLoading(true);
      const { data } = await axiosClient({
        method: "get",
        url: isNormal
          ? service.NURSING_CARE_PLAN
          : service.DISABILITY_CARE_PLAN,
        params: {
          patient_id: id,
          year_month: year_month.format(MONTH_FORMAT),
        },
      });

      const { newData, longTermGoals, shortTermGoals } = toFEData(
        data,
        year_month,
        lastMonth,
        form
      );

      setLongTermGoals(longTermGoals as ILongTermGoal[]);
      setShortTermGoals(shortTermGoals as ILongTermGoal[]);
      form.setFieldsValue(newData);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setLongTermGoals(defaultLongTermGoals);
      setShortTermGoals(defaultShortTermGoals);
    }
  };

  const handleSubmit = async (formData: any) => {
    let isValidTimeRage = false;

    notification.destroy();

    const data = toBEData(
      {
        ...formData,
        schedules: formData?.schedules?.map((item: any) => {
          const startTime = dayjs()
            .set("hours", item?.start_hour)
            .set("seconds", item?.start_hour)
            .set("minutes", item?.start_minute);
          const endTime = dayjs()
            .set("hours", item?.end_hour)
            .set("minutes", item?.end_minute)
            .set("seconds", item?.end_hour);

          if (startTime.isSameOrAfter(endTime)) {
            isValidTimeRage = true;
          }

          if (startTime.isSameOrAfter(endTime)) {
            isValidTimeRage = true;
          }
          
          return {
            ...item,
            time: [startTime, endTime],
          };
        }),
      },
      id,
      month
    );

    if (isValidTimeRage) {
      return notification.error({
        message: "終了時間は開始時間より後に設定してください。",
      });
    }
    
    try {
      setSubmitting(true)
      const res = await axiosClient({
        method: formData.id ? "put" : "post",
        url: isNormal
          ? service.NURSING_CARE_PLAN
          : service.DISABILITY_CARE_PLAN,
        data,
      });
      toast.success(res.data.success);
      fetchInitialData();
      setIsEdit(false);
    } catch (error) {
      toast.error((error as string).toString());
      console.error(error);
    } finally {
      setSubmitting(false)
    }
  };

  const getServiceOptions = async () =>
    (
      await axiosClient.get(service.SERVICES, {
        params: {
          patient_type: isNormal ? "normal" : "disability",
          get_all: true,
        },
      })
    ).data.data.map((item: IService) => ({
      label: item.service_name,
      value: item.id,
    }));

  const getServiceTypeOptions = async () =>
    (
      await axiosClient.get(service.SERVICE_TYPES, {
        params: {
          get_all: true,
        },
      })
    ).data.data.map((item: IServiceType) => ({
      label: item.detail,
      value: item.id,
    }));

  const getTreatmentOptions = async () =>
    (
      await axiosClient.get(service.TREATMENT_IMPROVEMENTS, {
        params: {
          patient_type: isNormal ? "normal" : "disability",
          get_all: true,
        },
      })
    ).data.data.map((item: ITreatmentType) => ({
      label: item.name,
      value: item.id,
    }));

  const handleResetPlanGoals = (name: string) => {
    form.setFieldValue(name, "");
  };

  const handlePreSubmit = () => {
    setIsSubmit(true);
  };

  const handleSubmitFailed = () => {
    setIsShowScheduleError(true);
  };

  useEffect(() => {
    fetchInitialData();
  }, [month]);

  const columns = [
    {
      align: "center",
      width: 180,
      title: "サービス名称",
      render: (value: any, record: any, index: any) => (
        <>
          <Form.Item name={[index, "id"]} hidden>
            <div />
          </Form.Item>
          <Form.Item
            name={[index, "service_id"]}
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
            ]}
            className="m-0 w-[180px]"
          >
            <SelectWithData
              placeholder="選択してください"
              getOptions={getServiceOptions}
              className="w-full"
            />
          </Form.Item>
        </>
      ),
    },
    {
      align: "center",
      width: 300,
      title: "提供時間",
      render: (_value: any, _record: any, index: any) => {
        const schedule = form.getFieldValue("schedules")?.[index];

        const startTime = dayjs()
          .set("hours", schedule?.start_hour)
          .set("minutes", schedule?.start_minute);

        const endTime = dayjs()
          .set("hours", schedule?.end_hour)
          .set("minutes", schedule?.end_minute || 0);

        if (dayjs(startTime).isAfter(endTime)) {
          form.setFieldValue(["schedules", index, "end_hour"], undefined);

          form.setFieldValue(["schedules", index, "end_minute"], undefined);
        }

        if (
          schedule?.end_minute === HAFT_HOUR &&
          schedule?.end_hour === HOURS_PER_DAY
        ) {
          form.setFieldValue(["schedules", index, "end_minute"], undefined);
        }

        return (
          <div className="flex gap-[10px] items-center">
            <div className="flex">
              <Form.Item name={[index, "start_hour"]} className="m-0">
                <Select
                  style={{
                    width: 70,
                  }}
                  options={hours?.filter((item) => item.value < HOURS_PER_DAY)}
                />
              </Form.Item>

              <Form.Item name={[index, "start_minute"]} className="m-0">
                <Select
                  style={{
                    width: 70,
                  }}
                  options={minutes}
                  disabled={schedule?.start_hour === undefined || !isEdit}
                />
              </Form.Item>
            </div>
            -
            <div className="flex">
              <Form.Item name={[index, "end_hour"]} className="m-0">
                <Select
                  style={{
                    width: 70,
                  }}
                  options={hours?.map((item) => {
                    return {
                      ...item,
                      disabled:
                        item?.value <
                        (schedule?.start_minute === 0
                          ? schedule?.start_hour
                          : schedule?.start_hour + 1),
                    };
                  })}
                  disabled={schedule?.start_minute === undefined || !isEdit}
                />
              </Form.Item>

              <Form.Item name={[index, "end_minute"]} className="m-0">
                <Select
                  style={{
                    width: 70,
                  }}
                  options={minutes?.map((item) => {
                    return {
                      ...item,
                      disabled:
                        item?.value === HAFT_HOUR &&
                        schedule?.end_hour === HOURS_PER_DAY,
                    };
                  })}
                  disabled={schedule?.end_hour === undefined || !isEdit}
                />
              </Form.Item>
            </div>
          </div>
        );
      },
    },
    {
      align: "center",
      width: 180,
      title: "サービス内容",
      render: (_value: any, _record: any, index: any) => (
        <Form.Item
          name={[index, "service_type_id"]}
          rules={[
            {
              required: true,
              message: MESSAGES.requireField,
            },
          ]}
          className="m-0 w-[180px]"
        >
          <SelectWithData
            className="w-full"
            placeholder="排泄介助"
            getOptions={getServiceTypeOptions}
          />
        </Form.Item>
      ),
    },
    ...Object.values(DAY_IN_WEEK).map((item, index) => {
      return {
        align: "center",
        className: "col-date",
        title: item,
        render: (_value: any, _record: any, rowIndex: any) => {
          const isError =
            schedules?.[rowIndex]?.shift_dates?.every(
              (item: boolean) => item === false
            ) && isSubmit;

          return (
            <Form.Item
              name={[rowIndex, "shift_dates", index]}
              initialValue={false}
              valuePropName="checked"
              noStyle
            >
              <Checkbox
                onChange={() => form.validateFields(["schedules"])}
                className={
                  isError
                    ? "[&_.ant-checkbox-inner]:!border-[red] [&_.ant-checkbox-inner]:!border-[1px]"
                    : ""
                }
              />
            </Form.Item>
          );
        },
      };
    }),
    {
      align: "center",
      width: 180,
      title: "頻度",
      render: (_value: any, _record: any, index: any) => (
        <Form.Item
          name={[index, "frequency"]}
          rules={[
            {
              required: true,
              message: MESSAGES.requireField,
            },
          ]}
          className="m-0 min-w-[105px]"
        >
          <SelectWithData
            className="min-w-[105px]"
            placeholder="毎週"
            options={frequencyOptions}
          />
        </Form.Item>
      ),
    },
    {
      align: "center",
      width: 180,
      title: "対応シフト",
      render: (_value: any, _record: any, index: any) => (
        <Form.Item
          name={[index, "shift_type"]}
          rules={[
            {
              required: true,
              message: MESSAGES.requireField,
            },
          ]}
          className="m-0 min-w-[105px]"
        >
          <SelectWithData
            className="min-w-[105px]"
            placeholder="早番"
            options={shiftOptions}
          />
        </Form.Item>
      ),
    },
  ];


  const handleDragEnd = (e: DragEndEvent) => {
    if (e.over?.id && e.active.id != e.over?.id) {
      const schedules = form.getFieldValue("schedules");
      const activeIndex = toNumber(e.active.id);
      const overIndex = toNumber(e.over.id);
      const newSchedules = arrayMove(schedules, activeIndex, overIndex);
      form.setFieldValue("schedules", newSchedules);
    }
  };

  return (
    <Styles>

      <Form
        onFinishFailed={handleSubmitFailed}
        disabled={!isEdit}
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        initialValues={initialFormValues}
        labelWrap
        scrollToFirstError={{ block: "center" }}
      >
        <FlexRow className="justify-between">
          <SelectTime
            format={MONTH_FORMAT}
            type="month"
            value={month}
            onChange={setMonth}
          />
          {!isStaff && (
            <FlexRow>
              <ButtonSolid
                onClick={() => {
                  fetchInitialData(true);
                }}
                hidden={!isEdit}
                width="137px"
                height="35px"
                fontSize={14}
              >
                先月よりコピー
              </ButtonSolid>
              <ButtonSolid
                hidden={isEdit}
                width="137px"
                height="35px"
                fontSize={14}
                onClick={() => setIsEdit(true)}
              >
                編集
              </ButtonSolid>
            </FlexRow>
          )}
        </FlexRow>
        <Spin spinning={loading}>
          <div className="text-left">
            <StyleTitle>■介護</StyleTitle>
            <Form.Item
              name="comprehensive_aid_policy"
              label="総合的な援助の方針"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="individual_family_intention"
              label="本人及び家族の意向"
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item name="aid_purpose" label="援助、目的">
              <Input.TextArea />
            </Form.Item>

            {isNormal && (
              <div
                className={`grid grid-cols-${isStaff && isMobile ? "1" : "2"} ${
                  isTablet ? "gap-x-[30px]" : "gap-x-[73px]"
                }`}
              >
                <CareSection
                  title="◯長期目標"
                  isEdit={isEdit}
                  longTermGoals={longTermGoals}
                  isLongTerm={true}
                  setGoals={setLongTermGoals}
                  onRemoveGoals={handleResetPlanGoals}
                />
                <CareSection
                  title="◯短期目標"
                  isEdit={isEdit}
                  isLongTerm={false}
                  longTermGoals={shortTermGoals}
                  setGoals={setShortTermGoals}
                  onRemoveGoals={handleResetPlanGoals}
                />
              </div>
            )}

            <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯予定表
            </Text>
          </div>

          <Form.List
            name="schedules"
            rules={[
              {
                validator: (_rule, value) => {
                  if (
                    value?.some(
                      (item: any) =>
                        !item.shift_dates?.filter((i: any) => i).length
                    )
                  )
                    return Promise.reject();
                  return Promise.resolve();
                },
                message: "入力されていない項目があります。再度ご確認ください",
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  <TableDragDrop
                    dataSource={fields}
                    columns={columns}
                    isDisabled={!isEdit}
                    onDragEnd={handleDragEnd}
                  />
                  {isShowScheduleError && (
                    <Form.ErrorList errors={errors} className="text-[red]" />
                  )}
                  <FlexRow
                    style={{ justifyContent: "flex-end", marginRight: "75px" }}
                    className="mt-2"
                  >
                    <Button
                      hidden={!isEdit}
                      onClick={() => {
                        add();
                        setIsShowScheduleError(false);
                        setIsSubmit(false);
                      }}
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Plus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                    <Button
                      hidden={!isEdit}
                      onClick={() => {
                        fields.length > 1 && remove(fields.length - 1);
                        setIsSubmit(false);
                      }}
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Minus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                  </FlexRow>
                </>
              );
            }}
          </Form.List>

          <Box height="85px" />
          <Form.Item name="note" label="メモ欄" className="mt-10">
            <Input />
          </Form.Item>
          <Text fontSize="14px" fontWeight="bold" mb="12px">
            ◯処遇改善加算
          </Text>
          <Form.List name="improvement_nursing_care_plans" initialValue={[{}]}>
            {(fields, { add, remove }) =>
              fields.map((item, index) => (
                <div key={index} className="flex items-center mb-3">
                  <Form.Item name={[index, "id"]} hidden>
                    <div />
                  </Form.Item>
                  <Form.Item
                    label="項目名"
                    name={[index, "treatment_improvement_id"]}
                    className="mb-0 w-full max-w-[700px]"
                  >
                    <SelectWithData
                      placeholder="選択してください"
                      getOptions={getTreatmentOptions}
                      allowClear
                    />
                  </Form.Item>
                  <Button
                    hidden={!isEdit}
                    onClick={() => add(undefined, index + 1)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    ml="2"
                    border="1px solid #000"
                  >
                    <img
                      src={Plus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                  <Button
                    hidden={!isEdit}
                    onClick={() => fields.length > 1 && remove(index)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    border="1px solid #000"
                  >
                    <img
                      src={Minus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                </div>
              ))
            }
          </Form.List>
          <Form.Item name="remarks" label="備考" className="mt-10">
            <Input.TextArea />
          </Form.Item>
          <div className="flex items-center justify-between">
            <div>
              {!isStaff && (
                <Link
                  to={`${ROUTES.EDIT_HISTORY}?changeable_type=${
                    isNormal ? "NursingCarePlan" : "DisabilityCarePlan"
                  }&changeable_id=${changeableId}`}
                  hidden={!changeableId}
                >
                  <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                    <ClockCircleFilled />
                    <span>履歴一覧</span>
                  </div>
                </Link>
              )}
            </div>
            <div className="flex gap-x-[8px]" hidden={!isEdit}>
              <ButtonOutline
                onClick={() => {
                  setIsEdit(false);
                  fetchInitialData();
                }}
                className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
              >
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
                type="submit"
                onClick={handlePreSubmit}
                isLoading={submitting}
              >
                保存
              </ButtonSolid>
            </div>
          </div>
        </Spin>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Styles>
  );
};

export default CarePlan;
